import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { react2angular } from 'react2angular';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Grid } from '../StyleGuide';
import { Input, InputContainer } from '../components/FormElements';
import { PrimaryButton as Button } from '../components/Buttons';
import { Container, PageTitle } from '../components/Layout';
import ErrorMessage from '../components/ErrorMessage';
import { SuccessMessage } from '../components/AlertMessages';
import windowService from '../services/windowService';
import userService from '../services/userService';
import alertService from '../services/AlertService';
import useQueryParams from '../hooks/useQueryParams';

const PageContainer = styled(Container)`
  margin: ${Grid._6} auto;
`;

const FormContainer = styled(Form)`
  display: flex;
  flex-wrap: wrap;
  gap ${Grid._4};

  > div {
    flex: 1 1 520px;
  }

  > button {
    flex: 1;
    min-width: 220px;
  }
`;

const EndedMessage = styled(SuccessMessage)`
  max-width: 600px;
  margin: ${Grid._6} auto;
`;

const ActionState = {
  ENDED: 'ended',
};

const Impersonate = () => {
  const { action } = useQueryParams();

  const handleSubmit = ({ email }, { setSubmitting, setFieldError }) => {
    const formattedEmail = email
      .trim()
      .toLowerCase()
      .replace(/^mailto:/, '');

    if (!Yup.string().email().isValidSync(formattedEmail)) {
      setFieldError('email', 'Invalid email address');
      setSubmitting(false);
      return;
    }

    userService
      .impersonateUser(formattedEmail)
      .then(() => windowService.redirectTo('/dashboard'))
      .catch(error => {
        alertService.showError(error?.response?.data);
        setSubmitting(false);
      });
  };

  return (
    <PageContainer>
      {action === ActionState.ENDED && <EndedMessage>Your impersonation session has ended</EndedMessage>}

      <PageTitle className="mb-40">
        <h1>Impersonate</h1>
        <p>Enter the email address of the user to impersonate</p>
      </PageTitle>

      <Formik
        initialValues={{ email: '' }}
        validationSchema={Yup.object({
          email: Yup.string().required('An email address is required'),
        })}
        validateOnChange={false}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue, isSubmitting, errors }) => (
          <FormContainer>
            <InputContainer>
              <Input
                placeholder="Email Address"
                onChange={e => setFieldValue('email', e.target.value)}
                value={values.email}
                clearInput={() => setFieldValue('email', '')}
                disabled={isSubmitting}
                data-qa-hook="impersonationEmail"
                autoFocus
              />
            </InputContainer>
            <Button
              type="submit"
              data-qa-hook="impersonationImpersonate"
              disabled={isSubmitting}
              operating={isSubmitting}
            >
              Impersonate
            </Button>

            {errors?.email && <ErrorMessage>{errors.email}</ErrorMessage>}
          </FormContainer>
        )}
      </Formik>
    </PageContainer>
  );
};

export default Impersonate;

export const ImpersonationBanner = ({ user }) => {
  const stopImpersonation = () => {
    if (!user.impersonatingWas) return;

    userService
      .impersonateUser(user.impersonatingWas)
      .then(() => windowService.redirectTo(`/admin/impersonate?action=${ActionState.ENDED}`))
      .catch(error => {
        alertService.showError(error?.response?.data);
      });
  };

  return (
    !!user && (
      <div style={{ textAlign: 'center' }} onClick={stopImpersonation} data-qa-hook="impersonationBanner">
        IMPERSONATING: {user.logInEmail}
      </div>
    )
  );
};

ImpersonationBanner.propTypes = {
  user: PropTypes.object,
};

angular.module('lwNamb').component('impersonationBanner', react2angular(ImpersonationBanner));
